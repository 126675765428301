import CustomerAddresses from './CustomerAddresses.vue'
import CustomerMembers from './CustomerMembers.vue'
import CustomerProfile from './CustomerProfile.vue'
import { type ICustomerData } from '@/plugins/User'
import { type AxiosResponse } from 'axios'
import axios from 'axios-observable'
import { ref, type Ref, watch } from 'vue'

export const Components = {
  CustomerAddresses,
  CustomerProfile,
  CustomerMembers,
}

export interface ICustomerStore {
  customer: ICustomerData
  loading: boolean
}

export const useCustomer = (id: Ref<string>) => {
  const loading = ref(false)
  const customer = ref<ICustomerData | null>(null)
  const load = () =>
    axios.get(`/_/Customers/${id.value}/Details`).subscribe({
      next: (r: AxiosResponse<ICustomerData>) => r.data && (customer.value = r.data),
      complete: () => (loading.value = false),
    })
  if (id.value) {
    load()
  }
  watch(
    () => id.value,
    (id: string) => id && load(),
  )
  return {
    loading,
    customer,
    load,
  }
}
